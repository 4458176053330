import en from "@/locales/en.json";
import fa from "@/locales/fa.json";
import ar from "@/locales/ar.json";
import tr from "@/locales/tr.json";

const translations = { en, fa, ar, tr };

export const translate = (key, language) => {
  const data = translations[language];
  if (!data) return key; // Language not supported or key not found, return key as fallback

  const keys = key.split('.');
  let value = data;

  for (const nestedKey of keys) {
    value = value[nestedKey];
    if (!value) return key; // Key not found, return key as fallback
  }

  return value;
};