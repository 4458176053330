"use client"

import React, { createContext, useContext, useState, useEffect } from 'react';

export const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
    const getInitialLanguage = () => {
        if (typeof window !== 'undefined') {
            const pathLanguage = window.location.pathname.split("/")[1];
            if (['fa', 'en', 'ar', 'tr'].includes(pathLanguage)) {
                return pathLanguage;
            }
        }
        return 'fa'; // Default language
    };

    const [language, setLanguage] = useState(getInitialLanguage());
    const [isLanguageInitialized, setIsLanguageInitialized] = useState(false);

    useEffect(() => {
        setIsLanguageInitialized(true); // Language is now set
    }, [language]);

    // const changeLanguage = (newLanguage) => {
    //     if (['fa', 'en', 'ar', 'tr'].includes(newLanguage)) {
    //         setLanguage(newLanguage);
    //         const pathSegments = window.location.pathname.split("/");
    //         pathSegments[1] = newLanguage;
    //         const newPath = pathSegments.join("/");
    //         window.history.pushState(null, '', newPath);
    //     }
    // };

    // const changeLanguage = (newLanguage) => {
    //     if (['fa', 'en', 'ar', 'tr'].includes(newLanguage)) {
    //         setLanguage(newLanguage);
    //         document.cookie = `currentLang=${newLanguage}; path=/`; // Set cookie for current language
    //         const pathSegments = window.location.pathname.split("/");
    //         pathSegments[1] = newLanguage;
    //         const newPath = pathSegments.join("/");
    //         window.history.pushState(null, '', newPath);
    //     }
    // }

    const changeLanguage = (newLanguage) => {
        if (['fa', 'en', 'ar', 'tr'].includes(newLanguage)) {
          setLanguage(newLanguage);
      
          // Set cookie for current language (with expiration of 30 days)
          document.cookie = `currentLang=${newLanguage}; path=/; max-age=${30 * 24 * 60 * 60}`;
      
          const pathSegments = window.location.pathname.split("/");
          pathSegments[1] = newLanguage;
          const newPath = pathSegments.join("/");
          window.history.pushState(null, '', newPath);
        }
      };
      

    useEffect(() => {
        document.documentElement.lang = language;
        document.documentElement.dir = language === 'fa' || language === 'ar' ? 'rtl' : 'ltr';
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, changeLanguage, isLanguageInitialized }}>
            {children}
        </LanguageContext.Provider>
    );
};
